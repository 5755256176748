<template>
  <main>
    <PageTitleContainer><PageTitle>Clienti</PageTitle></PageTitleContainer>

    <FetchList :loading="loading">
      <template v-if="customers && customers.length > 0">
        <AwlTable :heading="tableHeadings" :rows="customers" striped>
          <template #column-key-name="{ row }">
            <span v-if="row.type === 1">{{ row.name }}</span>
            <span v-else>{{ row.name }} {{ row.surname }}</span>
          </template>

          <template #column-key-type="{ value }">
            {{ value === 1 ? 'Business' : 'Consumer' }}
          </template>

          <template #actions-content="{ row }">
            <ActionShow :to="{ name: 'customers.show', params: { id: row.id } }" />
          </template>
        </AwlTable>

        <AwlPagination :value="page" :totalPages="totalPages" @page="goToPage" />
      </template>
      <Alert v-else status="info">Nessun cliente trovato.</Alert>
    </FetchList>
  </main>
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';
import pageableMixin from '@/libs/Pagination/mixins/pageable.js';

import FetchList from '@/views/components/Layout/FetchList.vue';
import AwlTable from '@/libs/Table/components/AwlTable.vue';
import ActionShow from '@/libs/Table/components/Actions/ActionShow.vue';
import AwlPagination from '@/libs/Pagination/components/AwlPagination.vue';
import Alert from '@/libs/Feedback/components/Alert.vue';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import PageTitle from '@/views/components/Private/PageTitle.vue';

function beforeRouteEnterOrUpdate (to, from, next) {
  $api.listCustomers(null, null)
    .then(res => next(vm => {
      vm.customers = res?.data?.customers || [];
      vm.setMetadata(res?.data);
    }))
    .catch(() => next(from))
  ;
}

export default {
  mixins: [pageableMixin],
  components: { FetchList, Alert, AwlTable, ActionShow, AwlPagination, PageTitleContainer, PageTitle },
  data () {
    return {
      loading: false,
      customers: null,
    };
  },
  computed: {
    tableHeadings () {
      return [
        { key: 'completeName', label: 'Name' },
        { key: 'type', label: 'Type' },
        { key: 'fiscalCode', label: 'Fiscal code' },
        { key: 'vat', label: 'Vat' },
        { key: 'country', label: 'Country' },
      ];
    },
  },
  methods: {
    fetchResults (page = this.page) {
      return this.$api.listCustomers(page, this.perPage)
        .then(res => {
          this.customers = res?.data?.customers || [];
          this.setMetadata(res?.data);
        })
        .catch(this.$log.error)
      ;
    },
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,
};

</script>
